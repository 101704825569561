import pengu from './image/pengu.gif';
import './App.css';

function App() {
  return (
    <div className="main">
      Soon...
      <img src={pengu} className="pengugif" alt="gif" />
    </div>
  );
}

export default App;
